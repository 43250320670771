<template>
    <div class="flex gap-3">
        <a
            v-for="rating in ratingOptions"
            :key="rating"
            href="#"
            class="flex items-center justify-center w-10 h-10 rounded-full mb-0 border border-gray-300 cursor-pointer"
            :class="[{ rating_current: isCurrent(rating), [ratingClass]: isCurrent(rating) }]"
            @click.prevent="handleRatingClick(rating)"
        >
            <span class="font-semibold text-lg">{{ rating }}</span>
        </a>
    </div>
</template>

<script>
export default {
    name: 'ReviewRating',
    props: {
        modelValue: {
            type: Number,
            required: true,
        },
        ratingOptions: {
            type: Array,
            default: () => [5, 4, 3, 2, 1],
        },
    },
    emits: ['update:modelValue'],
    computed: {
        ratingClass() {
            return `rating_${this.modelValue}`;
        },
    },
    methods: {
        handleRatingClick(val) {
            this.$emit('update:modelValue', val);
        },
        isCurrent(rating) {
            return this.modelValue === rating;
        },
    },
};
</script>

<style lang="scss" scoped>
.rating {
    &_current {
        @apply text-white;
    }

    &_5 {
        background-color: #2abe64;
    }
    &_4 {
        background-color: #9ac131;
    }
    &_3 {
        background-color: #febe2d;
    }
    &_2 {
        background-color: #fd8d38;
    }
    &_1 {
        background-color: #fd4e5f;
    }
}
</style>
