export const reviewsMixin = {
    data() {
        return {
            loading: false,
            data_loading: false,
            form: {
                user_name: null,
                published: false,
                ship_id: null,
                cruise_date: null,
                cruise_title: null,
                review: null,
                review_date: null,
                rating: null,
                images: null,
                images_delete: [],
            },
            selectShip: null,
            ships: [],
            imagesPreview: null,
        };
    },
    methods: {
        async fetchSingleData() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/reviews/${this.$route.params.id}/edit`);
                this.fillForm(data.data);
                this.loading = false;
                this.data_loading = true;
            } catch (ex) {
                console.log('cant fetch review: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
        async fetchSearchParamsShip(query = null) {
            try {
                // this.loading = true;
                const { data } = await this.axios.get('/ships-all', { params: { query: query } });
                // this.loading = false;
                return data.data;
            } catch (ex) {
                // this.loading = false;
                this.errorResponse(ex);
            }
            return null;
        },
        fillForm(data) {
            for (let [key, val] of Object.entries(data)) {
                if (key === 'images') {
                    this.form.images = [];

                    if (data?.images) {
                        this.imagesPreview = data.images;
                    } else {
                        this.imagesPreview = null;
                    }
                } else if (key === 'published') {
                    this.form[key] = !!val;
                } else if (key === 'rating') {
                    this.form[key] = +val;
                } else {
                    this.form[key] = val;
                }
            }

            this.form.images_delete = [];
            this.selectShip = data.ship;
        },
    },
};
